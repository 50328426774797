<script>
export default {
  name: 'FleetInvoicingView',
};
</script>

<template>
  <div class="FleetInvoicingView">
    <RouterView />
  </div>
</template>
